import React from "react"
import Topic from "../../components/Topic"
import { Link } from "gatsby"

const Employment = () => (
  <Topic id="employment">
    <p>
      Canada welcomes thousands of newcomers every year and celebrates their
      contribution to the workforce. Being able to speak the language plays an
      important role in finding a job. Most people find it helpful to learn the
      language first before looking for a job. Your first year in Canada is a
      good time to focus on improving your English or French. There are free, or
      low cost, English as a Second Language (ESL) classes for adults offered by
      local school boards, settlement agencies as well as some churches.
    </p>
    <p>
      Looking for a job can be challenging. Your credentials or education from
      your home country might not be recognized here. Find out what steps you
      need to take to work in your area of training here in Canada. You might
      also want to consider working in an area that is different from what you
      did in your home country.
    </p>
    <p>
      Connections through family and friends is a good way to find job
      opportunities. Local{" "}
      <Link to="/settling/settlement_agencies/">settlement agencies</Link> can
      also assist in your job search by helping you prepare a Canadian style
      resume and showing you where to find job postings.
    </p>
    <p>
      This section includes information on how to look for a job, training
      programs offered by the government, how to write a resume, links to job
      opportunities and where to find support during your job search.
    </p>
  </Topic>
)

export default Employment
